import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../CSS/styles.css';
const AboutMe = () => {
  return (
    <div className='container col-xxl-8 px-4 py-5'>
      <div className='row flex-lg-row-reverse align-items-center g-5 py-5'>
        <div class='col-lg-6'>
          <div className='feature col'>
            <div className='feature-icon  bg-gradient'>
              <h2 className='fw-light titleStyle'>Benjamin Anoh</h2>

              <h5 classNameName=''>iOS Developer | Photographer.</h5>
            </div>
            <p className='py-3 text-secondary'>
              My name is Ben. I am a iOS Software Engineer and Photography
              lover.
              <br />
              <p>
                My interests are Portrait and Lifestyle Photography. My goal as
                a Photographer is to capture your personality, identity, soul,
                and emotions by using effective poses. I am looking forward to
                working with you and capture some wonderful moment of your
                lives!
              </p>
            </p>
            <div className=''>
              <ul className='nav col-12 col-lg-auto my-2 justify-content-left my-md-0 text-small'>
                <li className=''>
                  <Link to='/apps'>
                    <a className='nav-link'>
                      <i className='fab fa-app-store-ios'></i>
                    </a>
                  </Link>
                </li>
                <li className=''>
                  <a
                    href='https://www.linkedin.com/in/kadjo-b-a-1bb753a9/'
                    className='nav-link'>
                    <i className='fab fa-linkedin-in'></i>
                  </a>
                </li>
                <li className=''>
                  <a
                    href='https://www.instagram.com/ben_anoh/?hl=en'
                    className='nav-link'>
                    <i className='fab fa-instagram'></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='col-sm-6 col-lg-6'>
          <img
            src={require(`../Download/ben.jpg`).default}
            class='d-block mx-lg-auto img-fluid'
            alt='Bootstrap Themes'
            loading='lazy'
          />
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
