import React, { Component } from 'react';
import '../CSS/styles.css';

const Policies = () => {
  return (
    //my-5
    <div class='container'>
      <div className='row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg'>
        <div className='row flex-lg-row-reverse align-items-center g-5 py-5'>
          <div className='col-md-8 p-lg-5 mx-auto my-5'>
            <h1 className='display-5 fw-bold lh-1 mb-3'>Privacy Policies</h1>
            <p>
              Built as a Free app. This application is provided by Kadjo
              Benjamin Anoh at no cost and is intended for use as is. This page
              is used to inform visitors regarding my policies with the
              collection, use, and disclosure of Information if anyone decided
              to use my app.I will not use or share your information with anyone
              except as described in this Privacy Policy.
            </p>

            <p>
              <b className='text-dark'>Information Collection and Use</b>
              <br />
              For a better experience, while using our Service, I may require
              you to provide us access to your photos. The information that I
              request will be saved on a secured Google API.
            </p>

            <p>
              <b className='text-dark'>Security</b>
              <br />I value your trust in providing us your Personal
              Information, thus we are striving to use commercially acceptable
              means of protecting it. But remember that no method of
              transmission of electronic storage is 100% secure and reliable,
              and I cannot guarantee its absolute security.
            </p>

            <p className='lead font-weight-normal' />
            <p>
              <b className='text-dark'>Changes to This Privacy Policy</b>
              <br />I may update our Privacy Policy from time to time. Thus, you
              are advised to review this page periodically for any changes. I
              will notify you of any changes by posting the new Privacy Policy
              on this page. This policy is effective as of 2021-05-22.
            </p>

            <p className='lead font-weight-normal' />
            <p>
              <b className='text-dark'>Contact Us</b>
              <br />
              If you have any questions or suggestions about my Privacy Policy,
              do not hesitate to contact me at benjaminanoh@gmail.com.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Policies;
