import React from 'react';
import '../CSS/styles.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const imagesWithMessages = [
  { folder: 'Birthday', image: 'birthday-1.jpg' },
  { folder: 'Graduation', image: 'graduation-2.jpg' },
  { folder: 'Pregnancy', image: 'pregnancy-1.jpg' },
  { folder: 'Portrait', image: 'portrait-6.jpg' },
  { folder: 'Professional', image: 'professional-1.jpg' },
];

const Portrait = () => {
  return (
    <div className='container mt-4'>
      <div className='row mx-auto'>
        {imagesWithMessages.map((item, index) => (
          <div key={index} className='col-lg-3 px-1 mb-3'>
            <Link
              to={{
                pathname: `/gallery/${index}`,
                state: { image: item.image, folder: item.folder },
              }}>
              <div className='image-container'>
                <img
                  src={
                    require(`../Images/${item.folder}/${item.image}`).default
                  }
                  className='img-fluid'
                  alt={`Image ${index + 1}`}
                  loading='lazy'
                  style={{ cursor: 'pointer' }}
                />
                <div className='overlay'>
                  <div className='overlay-text'>{item.folder}</div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portrait;
