import React from 'react';
import '../CSS/styles.css';
import {
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';

const DetailView = () => {
  const location = useLocation();
  const { index, folder } = location.state;

  const getImageArray = () => {
    if (folder == 'Graduation') {
      return [
        { image: 'graduation-1.jpg' },
        { image: 'graduation-2.jpg' },
        { image: 'graduation-8.jpg' },
        { image: 'graduation-9.jpg' },
        { image: 'graduation-3.jpg' },
        { image: 'graduation-4.jpg' },
        { image: 'graduation-5.jpg' },
        { image: 'graduation-6.jpg' },
        { image: 'graduation-7.jpg' },
      ];
    } else if (folder == 'Professional') {
      return [
        { image: 'professional-1.jpg' },
        { image: 'professional-3.jpg' },
        { image: 'professional-2.jpg' },
      ];
    } else if (folder == 'Pregnancy') {
      return [
        { image: 'pregnancy-1.jpg' },
        { image: 'pregnancy-2.jpg' },
        { image: 'pregnancy-3.jpg' },
        { image: 'pregnancy-4.jpg' },
        { image: 'pregnancy-5.jpg' },
        { image: 'pregnancy-6.jpg' },
      ];
    } else if (folder == 'Portrait') {
      return [
        { image: 'portrait-6.jpg' },
        { image: 'portrait-7.jpg' },
        { image: 'portrait-5.jpg' },
        { image: 'portrait-1.jpg' },
        { image: 'portrait-2.jpg' },
        { image: 'portrait-3.jpg' },
        { image: 'portrait-4.jpg' },
      ];
    } else if (folder == 'Birthday') {
      return [
        { image: 'birthday-1.jpg' },
        { image: 'birthday-6.jpg' },
        { image: 'birthday-2.jpg' },
        { image: 'birthday-4.jpg' },
        { image: 'birthday-3.jpg' },
        { image: 'birthday-5.jpg' },
      ];
    }
  };

  const images = getImageArray();

  return (
    <div className='container'>
      {/* <div className='row mx-auto'>
        <h1 className='headerTitle'>{folder}</h1>
      </div> */}
      <div className='row mx-auto mt-4'>
        {images.map((item, index) => (
          <div key={index} className='col-lg-4 px-1 mb-3'>
            <div className='image-container'>
              <img
                src={require(`../Images/${folder}/${item.image}`).default}
                className='img-fluid'
                alt={`Image ${index + 1}`}
                loading='lazy'
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DetailView;
