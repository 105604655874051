import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const PageNotFount = () => {
  return (
    <div className=' container p-5 mb-4 rounded-3'>
      <div className='container-fluid py-5'>
        <h1 className='display-5 fw-bold text-danger'>Page not found</h1>
        <p className='col-md-8 fs-4'>
          Check the url and try again or navigate using the navigation bar.
        </p>
        <Link className='btn btn-dark btn-lg' to='/' type='button'>
          Home page
        </Link>
      </div>
    </div>
  );
};

export default PageNotFount;
