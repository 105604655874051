import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import '../CSS/styles.css';

const Projects = () => {
  return (
    <React.Fragment>
      <div className='container col-xxl-8 px-4 py-5'>
        <div className='row flex-lg-row-reverse align-items-center g-5 py-5'>
          <div className='col-10 col-sm-8 col-lg-6'>
            <img
              src={require(`../Images/Projects/food-recipes.png`).default}
              class='d-block mx-lg-auto img-fluid'
              alt='Bootstrap Themes'
              width='250'
              height='500'
              loading='lazy'
            />
          </div>

          <div className='col-lg-6'>
            <div className='feature col'>
              <div className='feature-icon  bg-gradient'>
                <h2 className='display-5 fw-bold lh-1 mb-3'>Food Recipes</h2>
              </div>
              <p>
                Eat Healthy is a food recipe that gives you a list of easy
                recipe you can try at home. Active the notification to get
                notified every time a recipe is added. Recipes are collected
                from well known website with a direct link to the recipe.
              </p>
              <div className='d-grid gap-2 d-md-flex justify-content-md-start'>
                <a
                  className='btn btn-outline-dark   px-4 me-md-2'
                  href='https://apps.apple.com/us/app/eat-healthy-food-recipes/id1580294209'>
                  <i class='fab fa-apple'> Download</i>
                </a>

                <Link
                  className='btn btn-dark px-4 me-md-2'
                  to='/food-recipes-policies'
                  style={{ textDecoration: 'none' }}>
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </div>

        <hr className='featurette-divider container' />

        <div className='row flex-lg-row-reverse align-items-center g-5'>
          <div className='col-lg-6'>
            <div className='feature col'>
              <div className='feature-icon  bg-gradient'>
                <h2 className='display-5 fw-bold lh-1 mb-3'>Gift Planner</h2>
              </div>
              <p>
                Gift Planner is an app with elegant UI that will help you create
                a wishlist of gifts for special occasions. Next time you have an
                event coming up you will find yourself ready-to-go without
                stress about what to bring.
              </p>
              <div className='d-grid gap-2 d-md-flex justify-content-md-start'>
                <a
                  className='btn btn-outline-dark   px-4 me-md-2'
                  href='https://apps.apple.com/us/app/gift-planner-wishlist/id1531753757'>
                  <i class='fab fa-apple'> Download</i>
                </a>

                <Link
                  className='btn btn-dark px-4 me-md-2'
                  to='/policies'
                  style={{ textDecoration: 'none' }}>
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>

          <div className='col-10 col-sm-8 col-lg-6'>
            <img
              src={require(`../Images/Projects/gift-planner.png`).default}
              class='d-block mx-lg-auto img-fluid'
              alt='Bootstrap Themes'
              width='250'
              height='500'
              loading='lazy'
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Projects;
