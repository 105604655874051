import React, { Component } from "react";
import "../CSS/styles.css";

const FoodRecipesPolicies = () => {
  return (
    //my-5
    <div class="container">
      <div className="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-md-8 p-lg-5 mx-auto my-5">
            <h1 className="display-5 fw-bold lh-1 mb-3">Privacy Policies</h1>
            <p>
              Built as a Free app. This application is provided by Kadjo
              Benjamin Anoh at no cost and is intended for use as is. This page
              is used to inform visitors regarding my policies with the
              collection, use, and disclosure of Information if anyone decided
              to use my app.I will not use or share your information with anyone
              except as described in this Privacy Policy.
            </p>

            <p>
              <b className="text-dark fw-bold lh-1 mb-3">
                Information Collection and Use
              </b>
              <br />
              The App does not collect any information from the user.
            </p>

            <p>
              <b className="text-dark">Security</b>
              <br />
              While using the app, users can visit a website where the recipes
              came from. We will ensure that any website reference is checked
              before adding it to the database. But remember that everything can
              go wrong on the internet. If you experience any unsual behavior
              while visiting the rcipes websites feel free to send us an email
              at benjaminanoh@gmail.com
            </p>

            <p className="lead font-weight-normal" />
            <p>
              <b className="text-dark">Changes to This Privacy Policy</b>
              <br />I may update our Privacy Policy from time to time. Thus, you
              are advised to review this page periodically for any changes. I
              will notify you of any changes by posting the new Privacy Policy
              on this page. This policy is effective as of 2021-08-08.
            </p>

            <p className="lead font-weight-normal" />
            <p>
              <b className="text-dark fw-bold">Contact Us</b>
              <br />
              If you have any questions or suggestions about my Privacy Policy,
              do not hesitate to contact me at benjaminanoh@gmail.com.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FoodRecipesPolicies;
